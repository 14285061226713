import React, { useState, useEffect, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


import './Work.css';
import './ProgIcon.css';
import "../../font/Raleway-Regular.ttf"
import '../../font/MTCORSVA.ttf'

import header from '../../asset/header.png'
import Banderole from '../../asset/Banderole.png'
import chateau from '../../asset/chateau.jpg'
import salle from '../../asset/salle.jpg'
import fleures from '../../asset/fleures.jpg'
import pet from '../../asset/pet.png'

import repas from '../../asset/repas.png'
import vin from '../../asset/vin.png'
import ceremonie from '../../asset/ceremonie.png'
import fleureNoir from '../../asset/fleureNoir.jpg'
import bal from '../../asset/bal.png'
import salle2 from '../../asset/salle2.jpg'
import programme from '../../asset/programme.png'
import icon from '../../asset/icon.jpg'
import genghis from '../../asset/genghis.jpg'
import jardin from '../../asset/jardin.jpg'


const logi = [{
  title: 'Logis Hostellerie du Châtel',
  add1: '17 avenue du Général de Gaulle',
  add2: '77370 NANGIS',
  distance: '14,7km',
  tel: '01 64 08 22 50',
  site: 'https://www.lechatel.eu/',
  prix: '79€/NUIT POUR 2'
},
{
  title: 'Les Crinières en Brie',
  add1: '485 rue de la Mairie',
  add2: '77720 SAINT OUEN EN BRIE',
  distance: '6,7km',
  tel: '01 64 08 44 18',
  site: 'https://lescrinieresenbrie.com/',
  prix: '88€/NUIT POUR 2'
},
{
  title: 'Hôtel Ibis château de Fontainebleau',
  add1: '18 rue de la Ferrare',
  add2: '77300 FONTAINEBLEAU',
  distance: '25,2km',
  tel: '01 64 23 45 25',
  site: 'https://all.accor.com/hotel/1028/index.fr.shtml',
  prix: '69€/NUIT POUR 2'
},
{
  title: 'Domaine de Frévent',
  add1: 'CD 67 – Lieudit Frévent',
  add2: '77370 LA CHAPELLE RABLAIS',
  distance: '14,7km',
  tel: '01 60 67 52 78',
  site: 'https://domaine-de-frevent.com/',
  prix: '90€/NUIT POUR 2'
},
{
  title: 'Château des Moyeux',
  add1: '',
  add2: '77370 LA CHAPELLE RABLAIS',
  distance: '10,5km',
  tel: '01 64 08 49 51',
  site: 'http://www.chateau-des-moyeux.com/',
  prix: '160€/NUIT POUR 2'
},
{
  title: 'Château de Courtry',
  add1: '12 rue du Château',
  add2: '77115 SIVRY COURTRY',
  distance: '10,8km',
  tel: '01 60 69 36 01',
  site: 'https://www.tripadvisor.fr/Hotel_Review-g2209464-d2211190-Reviews-Chateau_de_Courtry-Sivry_Courtry_Seine_et_Marne_Ile_de_France.html',
  prix: '96€/NUIT POUR 2'
},
{
  title: 'Hôtel Ibis Melun',
  add1: '81 avenue de Meaux',
  add2: '77000 MELUN',
  distance: '17,9km',
  tel: '01 60 68 42 45',
  site: 'https://all.accor.com/hotel/0620/index.fr.shtml',
  prix: '50€/NUIT POUR 2'
},
{
  title: 'Logis Hôtel la Chaum’Yerres',
  add1: '1 avenue de la Libération',
  add2: '77390 CHAUMES-EN-BRIE',
  distance: '19,8km',
  tel: '01 64 06 03 42',
  site: 'https://www.tripadvisor.fr/Hotel_Review-g1067690-d1068210-Reviews-Logis_La_Chaum_yerres-Chaumes_En_Brie_Seine_et_Marne_Ile_de_France.html',
  prix: '79€/NUIT POUR 2'
},
{
  title: 'Campanile Melun Sud',
  add1: '346 rue du Capitaine Bernard de Poret',
  add2: '77190 DAMMARIE LES LYS',
  distance: '28km',
  tel: '01 64 37 51 51',
  site: 'https://www.campanile.com/fr-fr/?sr=NBCAFR&gclsrc=aw.ds&kClkId=221105144319657597&kVsId=221105144306517676&gclid=Cj0KCQjwk5ibBhDqARIsACzmgLQZ2htgiWfTGl-xwo2LESwE682F22lSBv0QNtlsXX1vL_S-5pHHoE8aApUDEALw_wcB',
  prix: '55€/NUIT POUR 2'
},
]
const ProgIcon = ({src, time, className, descr}) => (
  <div className={`${className} ProgIcon`}>
    <img className='ProgIcon-img' src={src}/>
    <p className='ProgIcon-text'>{time}</p>
    <p className='ProgIcon-descr'>{descr}</p>
  </div>
)

const Work = () => {

  const [day, setDay] = useState()
  const [minute, setMinute] = useState()
  const [hours, setHours] = useState()
  const [seconde, setSeconde] = useState()

  const total = Date.parse(new Date('2023-11-11')) - Date.parse (new Date ());
  
  useEffect(() => {
    setTimeout(() => {
      setDay( Math.floor(total / (1000 * 60 * 60 * 24)));
      setHours(Math.floor ((total / (1000 * 60 * 60))% 24));
      setMinute(Math.floor((total / 1000/60)% 60));
      setSeconde(Math.floor ((total / 1000)% 60));
    }, 1000);
  });

  const [presence, setPresence] = useState(0)
  const [resa, setResa] = useState(false)

  const name = useRef()
  const email = useRef()
  const detail = useRef()

  async function postResa(){
    
    const response = await fetch('https://back-viens-on-se-marie.osc-fr1.scalingo.io/users', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: name.current.value,
        resa: presence,
        message: detail.current.value,
        email: email.current.value
      })
    })
    return response.json()
  }

  const test = () => {
    postResa().then((data) => {
      console.log('data', data)
      data.status === 'success' && setResa(true)
    })
  }

  return (
    <div className="Work">
      <img src={header} className='Work-header'/>
      {/* Partie Presentation*/} 
      <div className='Work-P1'>
        <img className='Work-Banderole'src={Banderole}/>
        <p className='Work-Banderolle-text'> Nous sommes heureux de vous annoncer notre mariage !! </p>
      </div>
      {/* Card Part */}
      <div className='Work-Cards'>
        <div className='Work-Card'>
          <p className='Work-Card-title'>Participerez-vous?</p>
          <img className='Work-Card-img' src={fleures}/>
          <p className='Work-Card-text'>Dites-nous si vous nous rejoignez pour cette incroyable journée</p>
          <a className='Work-Card-link' href='#reservation'>Réserver</a>
        </div>
        <div className='Work-Card'>
          <p className='Work-Card-title'>Le Programme</p>
          <img className='Work-Card-img' src={salle}/>
          <p className='Work-Card-text'>Découvrez le planning qui vous attend.</p>
          <a className='Work-Card-link' href='#prog'>Voir</a>
        </div>
        <div className='Work-Card'>
          <p className='Work-Card-title'>Le lieu</p>
          <img className='Work-Card-img'  src={chateau}/>
          <p className='Work-Card-text'>Découvrez le lieu ainsi que les endroits ou vous pourrez loger dans les alentours</p>
          <a className='Work-Card-link' href='#lieu'>Découvrir</a>
        </div>
      </div>
      {/** Timer part */}
      <div className='Work-timer'>
        <p className='Work-bold Work-day'>{day}</p>
        <p>jours</p>
        <p className='Work-timer-message'><span className='Work-bold'>{hours}</span> heures <span className='Work-bold'>{minute}</span> minutes <span className='Work-bold'>{seconde}</span> secondes</p>
        <p>restants</p>
        <p className='Work-timer-message'><i>avant le grand jour</i></p>
        <p className='Work-timer-message'><i>11 novembre 2023</i></p>
      </div>
      {/* Participation */}
      { !resa ? (
        <div  className='Work-reservation' id='reservation'>
          <div>
            <img className='Work-reservation-center' src={fleureNoir}/>
            <p className='Work-reservation-center'>Merci de réserver avant le 15 janvier 2023</p>
          </div>
          
          <div className='Work-form-div'>
            <p>Nom(s) *</p>
            <input ref={name} className='Work-form-input' type="text"/>
          </div>
          <div className='Work-form-div'>
            <p>Email *</p>
            <input ref={email} className='Work-form-input'  type="email"/>
          </div>
          <div className='Work-form-div'>
            <p>Vous participez? *</p>
            <div style={{display: 'flex'}}>
              <input type="checkbox" id="scales" name="scales" onClick={() => setPresence(1)} checked={presence === 1}/>
              <p for="scales">Oui</p>
            </div>

            <div style={{display: 'flex'}}>
              <input type="checkbox" id="horns" name="horns" onClick={() => setPresence(2)} checked={presence === 2}/>
              <p for="horns">Non</p>
            </div>
          </div>
          <div className='Work-form-div'>
            <p>Autres détails: (Allergies, ...)</p>
            <input ref={detail} className='Work-form-input'  type="text"/>
          </div>
          <button className='Work-Card-link' disabled={name.current?.value === "" || email.current?.value === "" || presence === 0} onClick={test}>Envoyez votre réservation</button>
        </div>
        ) : (
          <div className='Work-reservation2' id='reservation'>
            <div>
              <img className='Work-reservation-center' src={fleureNoir}/>
            </div>
            <p className='Work-timer-message'> Votre réservation a bien été enregistré, merci.</p>
            <button className='Work-Card-link' onClick={() => {
              setResa(false);
              setPresence(0)
            }}>Envoyez une nouvelle réservation</button>
          </div>
        )
      }
      {/* Programme */}
      <div className='Work-prog1' id='prog'>
        <img className='Work-prog-band' src={programme} />
        <p style={{paddingBottom: '25px'}}>Nous vous accueillons au Nomade Lodge</p>
        <div className='Work-prog'>
          <div className='Work-prog-icon  Work-prog-1'>
            <ProgIcon className={'Work-prog-start'} src={ceremonie} time={'16h30'} descr='Cérémonie'/>
            <ProgIcon className={'Work-prog-end'} src={vin} time={'17h30'} descr="Vin d'honneur"/>
          </div>
          <div className='Work-prog-icon'>
            <ProgIcon className={'Work-prog-start'}  src={repas} time={'20h00'} descr='Début du repas'/>
            <ProgIcon className={'Work-prog-end'}  src={bal} time={'23h00'} descr='Ouverture du bal'/>
          </div>
        </div>
        <img className='Work-bande-2' src={salle2}/>
      </div>
      {/* Pet */}
      <div className='Work-pet-container'>
        <div className='Work-pet'>
          <img src={pet} className='Work-pet-img'/>
          <p className='Work-pet-text'> Les animaux ne sont pas admis durant le mariage. </p>
        </div>
      </div>
      {/* Lieu */}
      <div id='lieu'>
        <p className='Work-lieu-title'>Nomade Lodge</p>
        <p className='Work-lieu-text'>Nous vous invitons au Nomade Lodge, un lieu atypique qui vous accordera les plus beaux instants de notre mariage.</p>
        <p className='Work-lieu-text Work-lieu-text-2'>Le site verdoyant et exotique dotera notre cérémonie de mariage d'un cachet incomparable.</p>
        <div className='Work-lieu-carousel'>
          <Carousel
            autoPlay={true}
            interval='4000'
            infiniteLoop={true}
            showThumbs={false}
          >
            <div>
              <img src={genghis} />
            </div>
            <div>
              <img src={salle} />
            </div>
            <div>
              <img src={jardin} />
            </div>
          </Carousel>
        </div>
        <div className='Work-lieu-info'>
          <img src={icon} className='Work-lieu-icon'/>
          <div>
            <p className='Work-lieu-title'>Ferme de la boulaye</p>
            <p className='Work-lieu-text'>Route départemental 227</p>
            <p className='Work-lieu-text'>77720 La Chapelle-Gauthier</p>
          </div>
        </div>
        {/* Logement */}
        <div className='Work-pet-container'>
          <div className='Work-logi'>
            <p className='Work-pet-text'> Logements disponibles à proximité </p>
          </div>
        </div>
        {logi.map(e=> (
          <div className='Work-logi-block'>
              <a href={e.site} className='Work-lieu-title'>{e.title}</a>
              <p className='Work-lieu-text'>{e.add1}</p>
              <p className='Work-lieu-text'>{e.add2} ({e.distance})</p>
              <p className='Work-lieu-text'>{e.tel}</p>
              
              <p className='Work-lieu-text'>À PARTIR DE {e.prix}</p>
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className='Work-footer'>
        <h3>Contact</h3>
        <p>Mail: pauline06.roucache@gmail.com</p>
        <p>Tel Pauline: 06 70 66 59 01</p>
        <p>Tel Maxime: 06 25 73 65 95</p>
      </div>
    </div>
  );
}

export default Work;
