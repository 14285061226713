import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import { Work } from './page'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/cassoulet" element ={
            <div>vrai travaux</div>
            }/>
          <Route path="/" element={
            <Work/>
          }/>
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found etc</h2>
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
